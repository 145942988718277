import {Button, Container} from "react-bootstrap";
import {useParams} from "react-router-dom";
import DownloadAppImage from "./DownloadAppImage";

export default function ShareNote() {
    const { noteId } = useParams();
    const shareURL = `https://fizzle.hexploits.com/note/${noteId}`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareURL)
            .then(() => {
                alert("URL copied to clipboard!");
            })
            .catch((error) => {
                console.error("Error copying to clipboard:", error);
            });
    };

    return (
        <Container className={"page-container"}>
            <div className={"centered-content"}>
                <h1>Share your FizzleNote</h1>
                <p>{shareURL}</p>
                <Button variant={"dark"} onClick={copyToClipboard}>
                    Copy Link
                </Button>
            </div>
            <DownloadAppImage/>
        </Container>
    )
}