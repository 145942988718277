import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import NotePage from "./NotePage";
import Homepage from "./Homepage";
import NewNote from "./NewNote";
import ShareNote from "./ShareNotePage";
import SupportPage from "./SupportPage";
import PrivacyPage from "./PrivacyPage";

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<Homepage/>}/>
                <Route path={"/new"} element={<NewNote/>}/>
                <Route path={"/share/:noteId"} element={<ShareNote/>}/>
                <Route path={"/note/:noteId"} element={<NotePage/>}/>
                <Route path={"/support"} element={<SupportPage/>}/>
                <Route path={"/privacy"} element={<PrivacyPage/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
