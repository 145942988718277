import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './global.css'

const firebaseConfig = {
    apiKey: "AIzaSyBtALq0wQACDqBXGh8XtGkS-BUQu2Mn40Q",
    authDomain: "sdsn-hexploits.firebaseapp.com",
    projectId: "sdsn-hexploits",
    storageBucket: "sdsn-hexploits.appspot.com",
    messagingSenderId: "58272610674",
    appId: "1:58272610674:web:9976fc574206cc1c6c85eb",
    measurementId: "G-F8TE86RN2B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
