import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import DownloadAppImage from "./DownloadAppImage";

export default function NotePage() {
    const { noteId } = useParams();
    const [createdAt, setCreatedAt] = useState("");
    const [noteBody, setNoteBody] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [noteFound, setNoteFound] = useState(false);

    useEffect(() => {
        console.log("Fetching note...")

        const fetchNote = async () => {
            const db = getFirestore()
            const noteRef = doc(db, "notes", noteId)
            const noteSnapshot = await getDoc(noteRef)

            if (noteSnapshot.exists()) {
                const noteData = noteSnapshot.data();
                // Convert Firebase Timestamp to JavaScript Date object
                const createdAt = noteData.createdAt.toDate();
                // Format the date to a human-readable string
                noteData.createdAt = createdAt.toLocaleString();
                setNoteBody(noteData.noteBody);
                setCreatedAt(noteData.createdAt);
                setNoteFound(true);
                await deleteDoc(noteRef)
                console.log("This note has now been destroyed.")
            } else {
                console.log("No note found");
                setNoteFound(false);
            }

            setIsLoading(false); // Set isLoading to false after Firebase call
        };

        fetchNote();
    }, [noteId]);

    return (
        <div className="page-container">
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <NoteView noteBody={noteBody} createdAt={createdAt} noteFound={noteFound} />
            )}
        </div>
    );
};

function NoteView({ noteBody, createdAt, noteFound }) {
    const navigate = useNavigate();

    const redirectHome = () => {
        navigate("/");
    }

    return (
        <Container className="note-container">
            <Row className="justify-content-md-center">
                <Col md={6}> {/* Adjust the column size as needed */}
                    {noteFound ? (
                        <>
                            <h1>Your FizzleNote</h1>
                            <p>{createdAt}</p>
                            <hr />
                            <p>{noteBody}</p>
                            <hr />
                            <Button variant={"dark"} onClick={redirectHome}>
                                Destroy 💣
                            </Button>
                        </>
                    ) : (
                       <>
                           <h1>This FizzleNote has been destroyed or never existed.</h1>
                           <p>We'll never tell you which one it is 🥵</p>
                           <hr/>
                           <Button variant={"dark"} onClick={redirectHome}>
                               Go Home
                           </Button>
                       </>
                    )}
                </Col>
                <DownloadAppImage/>
            </Row>
        </Container>
    )
}
