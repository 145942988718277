import {Button, Container} from "react-bootstrap";
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import DownloadAppImage from "./DownloadAppImage";

export default function NewNote() {
    const [noteBody, setNoteBody] = useState("");
    const navigate = useNavigate();

    const submitNewNote = async() => {
        const db = getFirestore()
        const noteCollection = collection(db, "notes");

        try {
            const newNoteRef = await addDoc(noteCollection, {
                noteBody: noteBody,
                createdAt: serverTimestamp()
            });

            console.log("New note created with ID:", newNoteRef.id);
            navigate(`/share/${newNoteRef.id}`);
        } catch (error) {
            console.error("Error creating a new note:", error);
        }
    }

    return (
        <Container className={"page-container"}>
            <div className={"centered-content"}>
                <h1>Create a new FizzleNote</h1>
                <small>This note will be immediately destroyed when read</small>
                <hr/>
                <textarea rows={7} style={{ width: "100%" }}
                          value={noteBody}
                          onChange={(e) => setNoteBody(e.target.value)}/>
                <hr/>
                <Button variant={"dark"} onClick={submitNewNote}>
                    Secure and Share 🔐
                </Button>
            </div>
            <DownloadAppImage/>
        </Container>
    )
}