import {Button, Container} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

export default function PrivacyPage() {
    const navigate = useNavigate();

    const redirectHome = () => {
        navigate("/");
    }

    return (
        <Container className={"page-container"}>
            <div className={"centered-content"}>
                <h1>Privacy</h1>
                <hr/>
                <p>
                    We do no store or persist any user data, notes are temporarily stored in our database before being
                    read, then immediately wiped upon reading.
                </p>
                <p>
                    We do not store logs, ask you to login, or anything else.
                </p>
                <small>
                    If you would like to learn more about how the system works, please contact us at: <a href="mailto:info@hexploits.com">info@hexploits.com</a>
                </small>
                <hr/>
                <Button variant={"dark"} onClick={redirectHome}>
                    Go Home ✅
                </Button>
            </div>
        </Container>
    )
}