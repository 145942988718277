import {Button, Col, Container, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import DownloadAppImage from "./DownloadAppImage";

export default function Homepage() {
    const navigate = useNavigate()

    const redirectToNewNotePage = () => {
        navigate("/new")
    }

    return (
        <Container className={"page-container"}>
            <div className="centered-content">
                <h1>FizzleNote</h1>
                <p>Self destructing notes 💣</p>
                <Button variant={"dark"} style={{width: 300}} onClick={redirectToNewNotePage}>
                    New Note 📝
                </Button>
            </div>
            <DownloadAppImage/>
        </Container>
    )
}