import {Button, Container} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

export default function SupportPage() {
    let navigate = useNavigate();

    const redirectHome = () => {
        navigate("/");
    }
    return (
        <Container className={"page-container"}>
            <div className={"centered-content"}>
                <h2>Questions?</h2>
                <p>
                    📧 <a href={"mailto:info@hexploits.com"}>info@hexploits.com</a>
                </p>
                <hr/>
                <Button variant={"dark"} onClick={redirectHome}>
                    Go Home ✅
                </Button>
            </div>
        </Container>
    )
}