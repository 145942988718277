import React from "react";

export default function DownloadAppImage() {
    return (
        <div className={"bottom-download-app"}>
            <a href="https://itunes.apple.com/my-app-link" target={"_blank"} rel={"noopener noreferrer"}>
                <img src="../app-store.svg" alt="Download on the app store"/>
            </a>
        </div>
    )
}